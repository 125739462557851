import React from 'react';

const RuralHousing = () => {
  return (
    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">Rural Housing</h2>
            {/* <div class="d-flex justify-content-center">
              <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>Rural Housing</span>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">

            <h2 className='vasd_h2 text-center'>Rural Housing</h2>
           
             <p> A house is an indicator of social status of a family. It is also the means of healthy life and a good sleep. Moreover, having an appropriate shelter is one of the fundamental rights of a citizen. But unfortunately many people lack these rights. Most of the poor or ultra-poor and women headed families are living in shivering huts which is substandard and unhealthy as well as insecure. Since the people of this class have no financial ability to build a standard house as healthy, secured as well as standard to social dignity, they have to lead such inhumanly life. They are then vulnerable to natural shocks, diseases and unsecured to the miscreants.  
To address the problem, selected families are being provided with easy-to-get loan money for construction of houses and providing assistance through cooperating in all necessary arrangements. The house loan borrowers repay the loan by easy installments.
</p>
             <p> To address the problem, selected women are being provided with 50,000-80,000 taka loan for house making and assists in building house through cooperating in all necessary arrangements. The house loan borrowers repay the loan by installment.</p>
             <strong> Objectives of Rural Housing:</strong>
             <ul className='list-unstyled'>
             <li> A.To provide the rural poor with moderately stronger housing facilities</li>
             <li> B. To increase peace and social dignity of the poor and marginalized people</li>
             <li> C. To protect the poor households from natural shocks, disease and social insecurity</li>
             </ul>
             <ol >
             <li>  <strong> Beneficiaries: </strong>Poor, ultra-poor and women headed families and the households with disabled members</li>
             <li> <strong>Donor/Partner: </strong>Bangladesh Bank Grihayan tohabil</li>
             <li> <strong> Working area:</strong>Savar & Dhamrai upazila in Dhaka district and Manikganj district</li>
             <li> <strong> Project duration: </strong>2003 to till the date</li>
             </ol >
             <strong> Major Activities:Activities:</strong>
              <ul>
              <li> Beneficiary selection in according with set criteria</li>
              <li>Arrangements for providing loan money</li>
              <li>Assistance in collection of quality construction materials and construction works</li>
              <li>Counseling service for Income Generating </li>
              <li>Collection of Loan installments</li>
              <li>Follow-up</li>     
              <li>Report to Grihayan Tohabil as directed</li>
              </ul>
            
              <strong>Major achievement so far :</strong>
              <ul>
              <li> So far VASD has provided support to over 1300 households</li>
              </ul>
          </div>

        </div>

      </div>
    </section>
  );
};



export default RuralHousing;
