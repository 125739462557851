import React from 'react';

const DisabilityDevelopmentProgram = () => {
  return (

    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">Disability Development</h2>
            <div class="d-flex justify-content-center">
              {/* <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>Disability Development Program</span>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">
            <h2 className='vasd_h3 text-center'>Disability Development</h2>
            <p>

              In the way to endeavoring for the inclusion of the excluded people, disability development got the center place of VASD’s program designing in recent years. Major objective of the program is to mainstream the disability issue into the society through a package of comprehensive three-fold interventions. By working with this group of neglected people, VASD has gained experience on identification, psychosocial counseling, community awareness, rehabilitation and protection of the disabled persons. Facilitating the rights movement of these people also one of the remarkable interventions of the organisation. Success stories of the program tells about the achievement and effectiveness of interventions.
              <br />
              VASD believe in the rehabilitation and mainstreaming of the persons with disability by any means. Hence it takes different need based initiatives under following programatic areas:

              <p>   <strong>Three-fold actions: </strong></p>

              <table class="table table-striped">

                <tbody>
                  <tr>
                    <th scope="row">Protection</th>
                    <td><ul>
                      <li>	Survey and identification</li>
                      <li>	Physio Therapy</li>
                      <li>	Assistive device</li>
                      <li>	Orthosis and Prosthesis service</li>
                      <li>	Study support (education materials, financial support)</li>
                      <li>	Support in realizing Government allowances </li>
                      <li>Rights movement</li>
                      <li>	Referral </li>
                      <li>	Emergency assistance</li>
                    </ul>
                    </td>

                  </tr>
                  <tr>
                    <th scope="row">Prevention</th>
                    <td><ul>
                      <li>	Social awareness</li>
                      <li>	Caregiver development</li>
                      <li>	Lactating mother program</li>
                      <li>	Food and Nutrition program (VGD, awareness, Microfinance)</li>
                      <li>	Follow up support</li>

                    </ul>
                    </td>

                  </tr>

                  <tr>
                    <th scope="row">Rehabilitation</th>
                    <td>
                      <ul>
                      <li>Psycho-social counseling</li>
                      <li>IGA Skill development </li>
                      <li>Start-up capital</li>
                      <li>Simple Credit </li>
                      <li>Job placement </li>
                      <li>Self-help Group formation </li>
                      <li>Linkage with government and non-government agencies </li>


                    </ul>
                    </td>

                  </tr>




                </tbody>
              </table>



            </p>

          </div>
        </div>

      </div>
    </section>
  );
};
export default DisabilityDevelopmentProgram;
