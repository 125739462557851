import React from 'react';

const MicroFinanceProgram = () => {
  return (
    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">Micro Finance Program
            </h2>
            <div class="d-flex justify-content-center">
              {/* <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>Micro Finance Program
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">
          <h2 className='vasd_h2 text-center'>Micro Finance Program</h2>

       <p> VASD organizes different suitable packages through Microfinance Program to create poor people’s access to credit. Although microfinance takes a variety of forms, our objective is to provide access to small amounts of capital (often as little as 5,000 BDT or 10,000 BDT), so that people can invest in a small business. They may use the loan to buy a cow that will provide milk, a sewing machine that will make beautiful garments or a Rickshaw/ Van or a small piece of vegetable garden that will bring regular earning in a more efficient way. Each of these is an investment that helps entrepreneurs develop their ideas and start a business, enabling them to earn a living for themselves.</p>
       <p>VASD maintains utmost efficiency in the microfinance program delivery with an objective to balance between organisational requirements and people’s development. VASD strictly follow the regulations of Microcredit Regulatory Authority (MRA) as a registered member of MRA since 2008. During the journey VASD has had generous supporters in the microfinance program side by side its own fund. Namely they are : </p>
      <h4 className='vasd_h4'> Objectives of program:</h4>
       <ol>
       <li>  	Bangladesh Bank (Grihayon Tohabil)</li>
       <li>  	Basic Bank Limited</li>
       <li>  	Shahjalal Islami Bank Limited</li>
       <li>  	Midland Bank Limited</li>
       <li>  	Bangladesh Commerce Bank Limited.</li>
    
       </ol>     
      </div>
    </div>
</div>

    </section >
  );
};



export default MicroFinanceProgram;
