import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure Bootstrap is imported
import '../style/Banner.css'; // Make sure Bootstrap is imported


const Banner = () => {
  return (
    <section className="banner-bg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12  col-12  pt-5   text-center">

        
            <div className="vasd_banner_title" > Village Association for Social Development </div>

            <div><i>Striving for the inclusion of the excluded  </i> </div>
          </div>
        </div>

      </div>
    </section>


  );
}

export default Banner;
