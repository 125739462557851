import React from 'react';

const HandsForHumanity = () => {
  return (
    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">Hands For Humanity
            </h2>
            <div class="d-flex justify-content-center">
              {/* <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>Hands For Humanity
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">
            <h2 className='vasd_h2 text-center'>Hands For Humanity</h2>

            <p>
              <h3> Background</h3>
              Hands for Humanity is a humanitarian program jointly initiated by VASD, an NGO and Daraz Bangladesh Limited, a renowned e-commerce organisation in Bangladesh act as a facilitator to connect sellers with customers. The collaboration intends to furthering the shared goals of promoting positive social change and enhancing community welfare by donating the products for charitable purposes. VASD has also been endeavoring for community welfare and development for long time. Therefore, VASD values the generous support of Daraz Bangladesh limited and to extend hands for humanitarian aid to the people in need in its working areas.
            </p>

            <p>
              <h3>
                Major Objective of the Initiative</h3>
              Promoting positive social change and enhancing community welfare by donation of products and ensuring utilization.
            </p>

            <p>
              <h3>
                Date of Inception</h3>
              The "Hands for Humanity" program was launched on the 1st September 2024 following an MoU signed on 29th August 2024.
            </p>

            <p>
              <h3>

                Targeted beneficiaries and locations </h3>

              <h5>Following groups of people are being beneficial through this initiative:</h5>
              <ol>
                <li>  Distressed people, who cannot purchase desired quality goods</li>
                <li> Distressed women and children</li>
                <li>Students of the poorer section</li>
                <li> Disabled persons</li>
                <li>  Street children</li>
                <li> Beggar</li>
                <li> People from underprivileged communities</li>
              </ol>
            </p>

            <p><h3>Implementation area and method</h3>
              Hands for humanity program is being implemented in Savar Upazila of Dhaka district and Singair Upazila of Manikganj district among the targeted communities.
              VASD has deployed a team of dedicated staff who are responsible for:
              <br/>
               i) receiving goods   <br/>
               ii) warehouse management   <br/>
               iii) product sorting   <br/>
               iv) repackaging   <br/>
               v) beneficiary selection   <br/>
               vi) distribution  <br/>
               vii) documentation and reporting.  <br/>
              Beneficiaries are selected prudently according to the above mentioned criteria with an aim to provide support to the real destitute.
            </p>




          </div>
        </div>
      </div>

    </section >
  );
}

export default HandsForHumanity;
