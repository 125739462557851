import React from 'react';
import no_image from '../assets/images/ed_message.jpg';

const ExecutiveDirectorMessage = () => {
  return (
    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center"> Message </h2>
            <div class="d-flex justify-content-center">
              {/* <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>MD's Message</span>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 pt-5 pb-5">

            <div class="d-flex flex-column align-items-center">
              <img className='img-fluid' src={no_image} alt="Example" />
              <h5 className="text-center vasd_h5">Name:Masuma Samad,<br /> Executive Director</h5>
              <h5 className="text-center vasd_h5">Contact: 8801711 563174 </h5>
            </div>

          </div>
          <div className=" col-12 col-md-6 pt-5 pb-5">
            <h3 className='text-center'> Message</h3>
           <p className="pt-5"> Dear Honorable Visitor,<br />
            Welcome to the Official website of VASD, a non-governmental voluntary organisation in Bangladesh. You are cordially invited to explore the history, programs, news & information, services, events and collection of photographs, stories and reports and many more regarding the organisation. Please write to us for any more information you need to know.
            We are happy to let you know about us!</p>

            <div className="text-center p-4" style={{ width: '100%', }}>
              <span className='vasd_h2  m-2'><i class="bi bi-facebook"></i></span>
              <span className='vasd_h2  m-2'><i class="bi bi-twitter-x"></i></span>
              <span className='vasd_h2  m-2'><i class="bi bi-skype"></i></span>
              <span className='vasd_h2  m-2'><i class="bi bi-whatsapp"></i></span>
            </div>
          </div>
        </div>

      </div>
    </section>

  );
};



export default ExecutiveDirectorMessage;
