import React from 'react';


const ContactUs = () => {
  const sectionBg = {
    backgroundColor: '#002866',
    color: '#ffffff',
  };


  




  return (
    <section>
      <section>
      <div className="container-fluid">
        <div className="row page-titlebar">
          <h2 className="page-title text-center">Contact Us </h2>
          <div class="d-flex justify-content-center">
       
          </div>
        </div>
      </div>
      </section>

      <section style={sectionBg}>
        <div className="container">
          <div className="row ">
            {/* Left side: Google Form */}
            <div className="col-md-6">
              <div className="mb-4">

                <div className='fs-1'> <strong>Get In Touch?</strong></div>


                <div class="d-flex align-items-center">
                  <div class="me-4 fs-1">
                    <i className="bi bi-geo-alt-fill text-color-yellow"></i>
                  </div>

                  <div>
                    <h3 className='fs-4'><strong>Visit Us</strong></h3>
                    <p>

                    House # 77/3, Block-A, Talbag  <br />   
Thana Road, Savar<br />
Upazela : Savar, District: Dhaka<br />
Post Code: 1340, Bangladesh<br />
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="me-4 fs-1">
                  <i class="bi bi-headset text-color-yellow"></i>
                  </div>

                  <div>
                    <h3 className='fs-4'><strong>Phone Us</strong></h3>
                    <p>
                      Telephone: 02-7743925,
                      </p>   <p>
                      Cell: +8801711 563174
                      </p>   <p>
                      In case of any exclusive issue, 
•	write to the Executive Director 
: asumasamad@yahoo.com   
                    </p>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <div class="me-4 fs-1">
                  <i class="bi bi-envelope-check text-color-yellow"></i>
                  </div>

                  <div>
                    <h3 className='fs-4'><strong>Email Us</strong></h3>
                   <p>
                      E-mail: vasdho708@gmail.com
                    </p>
                  </div>
                </div>
                </div>



            </div>


            <div className="col-md-6 pt-5">

            <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3649.4013218781056!2d90.25228627426185!3d23.839878885337374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755ebc8d64db207%3A0x4c895df4d33cd3cd!2sVASD!5e0!3m2!1sen!2sbd!4v1741013424965!5m2!1sen!2sbd"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      />
   
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default ContactUs;
