// src/pages/Home.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS
import Banner from './Banner';
import OngoingProgramHomeSection from './OngoingProgramHomeSection';
import mra from '../assets/logo/mra.png';
import nab from '../assets/logo/nab.png';
import dss from '../assets/logo/dss.jpg';
import wab from '../assets/logo/bd.png';


const Home = () => {

  return (

    <section>
      <Banner />
      <OngoingProgramHomeSection />


      <section className='home_statics py-5 text-wrap'>

        <div className="container">
          <div className="row p-4">
            <div className="col-12  col-sm-5  pe-sm-5 mb-5 mb-sm-0" >
              <h3 className='vasd_h3 text-center text-sm-end'>Portfolio of Micro credit</h3>
              <h4 className='vasd-h4 text-center text-sm-end'>(as of 31 December 2024) </h4>
              <h1 className="vasd_h1 text-center text-sm-end  fs-1"> Tk. 7,82,84,905/-</h1>
              <h4 className="vasd_h4 text-center text-sm-end"> Loan outstanding</h4>
              <h1 className="vasd_h1 text-center text-sm-end  fs-1"> Tk. 2,47,59,731/-</h1>
              <h4 className="vasd_h4 text-center text-sm-end"> 	Savings Outstanding</h4>
              <h1 className="vasd_h1 text-center text-sm-end fs-1"> 272</h1>
              <h4 className="vasd_h4 text-center text-sm-end">  No. of groups</h4>
              <h1 className="vasd_h1 text-center text-sm-end fs-1">   3334  </h1>
              <h4 className="vasd_h4 text-center text-sm-end">  No. of members</h4>
              <h1 className="vasd_h1 text-center text-sm-end fs-1">   2645  </h1>
              <h4 className="vasd_h4 text-center text-sm-end">  No. of Borrowers</h4>

            </div>
            <div className="col-12 col-sm-7 ">
              <h2 className="vasd_h2 text-center text-sm-start fs-3" >

                Village Association for Social Development </h2>
              <h1 className="banner_h1 text-center text-sm-start fs-1">  VISION</h1>

              VASD envisage a poverty free, just and harmonious society where people live in peace with dignity.

              <h1 className="banner_h1 text-center text-sm-start fs-1">  MISSION</h1>

              VASD will raise people’s awareness on socio-economic development, organize and build up people’s capacity to implement development activities and achieve rights and entitlements.
              <div className="col-md-10 d-flex justify-content-center align-items-center mt-5">
                <a type="button" className="btn-contact" href="/about-us">About Us</a>
              </div>
            </div>
          </div>
        </div>
      </section>

  

      <section className='p-3 p-sm-5 mb-3 mb-sm-5 mt-3 mt-sm-5' style={{ backgroundColor: '#FFEE00', }}>
        <div className="container">
          <div className="row">
            <div className='col-12 col-sm-6 d-flex justify-content-center align-items-center '>
              <h3 className='vasd_h3 text-center'>Direct Contact </h3>
            </div>
            <div className='col-12 col-sm-6  d-flex justify-content-center align-items-center'>

              <div className="d-flex align-items-center">
                <div className="me-4 fs-1">

                  <i className="bi bi-headset " style={{ backgroundColor: '#FFEE00', Color: '#FFF', }}></i>

                </div>
                <div>
                  <h3 className='fs-4'><strong>8801711 563174 </strong></h3>
                  <p>
                    <strong>Executive Director</strong>
                  </p>
                </div>
              </div>
           
            </div>
          </div></div>
      </section>

      <section >
        <div className="container mb-sm-5 mb-2">
          <div className="row">
            <div className='vasd_h1 text-center mb-sm-5 mb-2 fs-1'> Legal Status</div>
            <div className=" col-6 col-sm-6 mb-2">
              <div className="  d-flex flex-column align-items-center image-container">
                <img src={dss} alt="Department of Social Service" />
                <h5 className="text-center vasd_h5">Department of Social Service</h5>
              </div> </div>
              <div className=" col-6 col-sm-6 mb-2">
              <div class="  d-flex flex-column align-items-center image-container">
                <img src={wab} alt="Example" />
                <h5 class="text-center vasd_h5">Office of the Registrar of Joint Stock Companies and Firmsn</h5>
              </div></div>
            <div className=" col-6 col-sm-6 mb-2">
              <div class="  d-flex flex-column align-items-center image-container">
                <img src={nab} alt="Example" />
                <h5 class="text-center vasd_h5">NGO Affairs Bureau</h5>
              </div></div>
            <div className=" col-6 col-sm-6 mb-2">
              <div class=" d-flex flex-column align-items-center image-container">
                <img src={wab} alt="Example" />
                <h5 class="text-center vasd_h5">Department of Women Affairs</h5>
              </div></div>
            <div className=" col-6 col-sm-6 mb-2">
              <div class="d-flex flex-column align-items-center image-container">
                <img src={mra} alt="Example" />
                <h5 class="text-center vasd_h5">Micro Credit Regulatory Authority (MRA)</h5>
              </div></div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Home;