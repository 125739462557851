import React, { useRef } from 'react';
import '../style/OngoingProgramHomeSection.css';

import disability_development from '../assets/images/disability_development.jpg';
import smmpreeti from '../assets/images/smmpreeti.jpg';
import hands_for_humanilty from '../assets/images/hands_for_humanilty.jpg';
import livelhood_extension from '../assets/images/livelhood_extension.jpg';
import micro_fnance from '../assets/images/micro_fnance.jpg';
import rural_housing from '../assets/images/rural_housing.jpg';

const OngoingProgramHomeSection = () => {


  // Sample data with vibrant images
  const programs = [
    { id: 1, title: 'Livelihood Extension', description: '', image: livelhood_extension, link: '/livelihood-extension' },
    { id: 2, title: 'Disability Development Program', description: '', image: disability_development, link: '/disability-development-program' },
    // { id: 2, title: 'SAMPREETI Program', description: '', image: smmpreeti, link: '/sampreeti' },
    { id: 3, title: 'Hands For Humanilty', description: '', image: hands_for_humanilty, link: '/hands_for-humanity' },
    { id: 4, title: 'Rural Housing', description: '', image: rural_housing, link: '/rural-housing' },
    { id: 5, title: 'Micro Finance Program', description: '', image: micro_fnance, link: '/micro-finance-program' },
  ];

  return (
    <section>
    <div className=" card-cntainer container-fluid my-5 ">
      <h2 className="text-center mb-4 vasd_h2">Ongoing Programs</h2>
      <div className='row d-flex justify-content-center'>
      <div class=" col-12 col-sm-4 mb-2 mb-sm-5">
        <div class="card overflow-hidden">
          <img src={livelhood_extension} class="card-img-top" alt="Card Image" />
          <div class="card-body">
            <h5 class="card-title text-center"><a className="nav-link" href="/livelihood-extension"> Livelihood Extension</a></h5>
            {/* <p class="card-text">This is another short description inside the card body.</p> */}
          </div>
        </div>
      </div>
      <div class=" col-12 col-sm-4 mb-2 mb-sm-5">
        <div class="card overflow-hidden">
          <img src={disability_development} class="card-img-top" alt="Card Image" />
          <div class="card-body">
            <h5 class="card-title text-center"><a className="nav-link" href="/disability-development-program">Disability Development Program</a></h5>
            {/* <p class="card-text">This is another short description inside the card body.</p> */}
          </div>
        </div>
      </div>



      <div class=" col-12 col-sm-4 mb-2 mb-sm-5">
        <div class="card overflow-hidden">
  
          <img src={hands_for_humanilty} class="card-img-top" alt="Card Image" />
          <div class="card-body">
            <h5 class="card-title text-center"><a className="nav-link" href="/hands_for-humanity">Hands For Humanilty</a></h5>
            {/* <p class="card-text">This is another short description inside the card body.</p> */}
      
      </div>   </div>  </div>
      <div class=" col-12 col-sm-4 mb-2 mb-sm-5">
        <div class="card overflow-hidden">
        
          <img src={rural_housing} class="card-img-top" alt="Card Image" />
          <div class="card-body">
            <h5 class="card-title text-center"><a className="nav-link" href="//rural-housing">Rural Housing</a></h5>
            {/* <p class="card-text">This is another short description inside the card body.</p> */}
            </div>
        </div>
      </div>
      <div class=" col-12 col-sm-4 mb-2 mb-sm-5">
        <div class="card overflow-hidden">
   
          <img src={micro_fnance} class="card-img-top" alt="Card Image" />
          <div class="card-body">
            <h5 class="card-title text-center"><a className="nav-link" href="/micro-finance-program">Micro Finance Program</a></h5>
            {/* <p class="card-text">This is another short description inside the card body.</p> */}
         
        </div>
      </div>
      </div>  
      </div> </div>
    </section>
  );
};

export default OngoingProgramHomeSection;
