import React from 'react';

const Donner = () => {
  return (
    <section>

      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">DONOR / DEVELOPMENT PARTNERS </h2>
            <div class="d-flex justify-content-center">
              {/* <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>DONOR / DEVELOPMENT PARTNERS</span>
              </div> */}
            </div>
          </div>
        </div>
      </section>


      <div className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pt-5 pb-5">

              <h1 className=' vasd_h3'>  Government Agencies</h1>
              <ol>
                <li> Department of Women Affairs/GoB</li>
                <li>Department of Non-Formal Education (DNFE)</li>
                <li>Department of Social Service (DSS)</li>
                <li>Secondary School Assistance Project (GoB) </li>
                <li>Bangladesh NGO Foundation (BNF)  </li>
                <li>Jatiya Protibondi Unnayan Foundation (JPUF)</li>
                <li>Bangladesh Bank Grihayon Tohabil</li>
              </ol>



              <h1 className=' vasd_h3'>  International Organisations</h1>
              <ol>
                <li>AusAID</li>
                <li>CARE Bangladesh</li>
                <li>CORDAID-the Netherlands</li>
                <li>German Technical cooperation /gtz</li>
                <li>Holiday INN-UK</li>
                <li>JAKJARA-UK (a software company)</li>
                <li>Save the Children USA</li>
              </ol>
              <h1 className=' vasd_h3'>National Organisations</h1>
              <ol>
                <li>Centre for Disability in Development (CDD) </li>
                <li>Concern BD  </li>
                <li>LCD-Bangladesh</li>
                <li>NGO Forum for Safe Water Supply & Sanitation</li>
                <li>Podokhep</li>
                <li>Proshika-MUK   </li>
                <li>Village Education Resource Center (VERC)</li>
                <li>World Vision</li>
              </ol>
              <h1 className=' vasd_h3'>Banks</h1>
              <ol>
                <li>Bangladesh Commerce Bank Ltd.</li>
                <li>Basic Bank limited, Bangladesh </li>
                <li>Midland Bank Ltd.</li>
                <li>Standard Bank limited, Bangladesh</li>
              </ol>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Donner;
