import React from 'react';


const PreviousProject = () => {


  return (
    <section>
    <section>
      <div className="container-fluid">
        <div className="row page-titlebar">
          <h2 className="page-title text-center fs-2"> Completed Projects
          </h2>
          <div class="d-flex justify-content-center">
            {/* <div class="dynamic-column text-center breadcrumbs p-3 ">
              Home | <span>Completed Projects
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </section>
    <div className="container">
      <div className="row">
        <div className="col-md-12 pt-5 pb-5">
        <h2 className='vasd_h2 text-center fs-2'>Completed Projects</h2>
        <div class="table-responsive">
        <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Sl. No</th>
          <th>Project Title</th>
          <th>Name of the Technical Partner </th>
          <th>Name of The Financial Partner</th>
          <th>Project Duration</th>
        </tr>
      </thead>
      <tbody>
      <tr>
          <td>1</td>
          <td>Promoting social cohesion and active citizenship through youth leadership and positive social engagement (DIPTYA Program)</td>
          <td>Manusher Jonno Foundation (MJF)</td>
          <td>UKAID</td>
          <td>November 2019 - June 2020</td>
        </tr>
      <tr>
          <td>2</td>
          <td>Social Action and Mobilization for Prevention of Radicalization and Extremism through Enhanced and Targeted Interventions Project (SAMPREETI)</td>
          <td>Manusher Jonno Foundation (MJF)</td>
          <td>Manusher Jonno Foundation (MJF)</td>
          <td>November 2018 - August 2019</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Sustainable Livelihood for the Disabled People (SLDP) Project</td>
          <td>Leonard Cheshire Disability, Bangladesh</td>
          <td>Not specified</td>
          <td>July 2015 - June 2018</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Strengthening Capacity and Networking of NSAs and LAs on Disability (SCANED) Project</td>
          <td>Center for Disability in Development (CDD)</td>
          <td>Light for the world - the Netherlands</td>
          <td>May 2014 - September 2017</td>
        </tr>
        <tr>
          <td>5</td>
          <td>CBR (Community Based Rehabilitation) Rollout in Bangladesh project</td>
          <td>CDD and NFOWD</td>
          <td>AusAID & cbm-German Implementing</td>
          <td>January 2012 - December 2016</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Livelihood Development of the Disabled Community</td>
          <td>Holiday Inn, USA</td>
          <td>Not specified</td>
          <td>January - December 2008</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Awareness raising and prevention of HIV/AIDS</td>
          <td>Holiday Inn, USA</td>
          <td>Save the Children USA</td>
          <td>2006-2009</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Community led total sanitation (CLTS) Program</td>
          <td>NGO Forum</td>
          <td>Not specified</td>
          <td>July 1997 - June 2012</td>
        </tr>
        <tr>
          <td>9</td>
          <td>Dissemination of Improved Cook Stove (ICS) Project</td>
          <td>VERC</td>
          <td>GIZ</td>
          <td>May - December 2008</td>
        </tr>
        <tr>
          <td>10</td>
          <td>Non-formal education</td>
          <td>DNFE</td>
          <td>Government</td>
          <td>2002</td>
        </tr>
        <tr>
          <td>11</td>
          <td>Local Government strengthening</td>
          <td>VERC</td>
          <td>Non-Government</td>
          <td>1996 to 2002</td>
        </tr>
        <tr>
          <td>12</td>
          <td>Adult Education</td>
          <td>Proshika-MUK</td>
          <td>Non-Government</td>
          <td>1996 to 2001</td>
        </tr>
        <tr>
          <td>13</td>
          <td>Educational Development program</td>
          <td>Secondary School Assistance Project</td>
          <td>Government</td>
          <td>1998</td>
        </tr>
        <tr>
          <td>14</td>
          <td>Micro Credit</td>
          <td>CARE INCOME Project-I, II & III</td>
          <td>Non-Government</td>
          <td>1997-2006</td>
        </tr>
        <tr>
          <td>15</td>
          <td>PLCEHD-1 Project (Non-formal Education Sector)</td>
          <td>Bureau of Non-formal Education</td>
          <td>Government</td>
          <td>July 2003 to June 2008</td>
        </tr>
        <tr>
          <td>16</td>
          <td>PLCEHD-III Project (Non-formal Education Sector)</td>
          <td>Bureau of Non-formal Education</td>
          <td>Government</td>
          <td>October 2005—June 2007</td>
        </tr>
        <tr>
          <td>17</td>
          <td>Arsenic Mitigation</td>
          <td>DPHE-GoB</td>
          <td>Not specified</td>
          <td>1998-1999</td>
        </tr>
      </tbody>
    </table>
    </div>
  
    </div>
  </div>
</div>

  </section >
  );
};

export default PreviousProject;
